import React from 'react';
import Navbar from './Navbar';

function Contact() {
  return (
    <div>
      <Navbar />
      <div className='contact'>
        <a href='mailto:yoavzadikov1@gmail.com'>yoavzadikov1@gmail.com</a>
        <br />
        <a href='tel:+972524336335'>+972524336335</a>
        <br />
        <a href='https://wa.me/972524336335'>
          <br />
          <i className='fa fa-whatsapp'></i>
        </a>
      </div>
    </div>
  );
}

export default Contact;
